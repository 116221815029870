.form {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px; */
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.form::-webkit-scrollbar {
    display: none;
}

.form {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

@media (min-width: 1200px) {
    .form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 8px;
    }
    .outer-div {
        width: 1200px;
    }
}

.line-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.col1 {
    grid-column: 1 / 2;
}

.col2 {
    grid-column: 2 / 3;
}

.col3 {
    grid-column: 3 / 4;
}

.col4 {
    grid-column: 4 / 5;
}

p {
    width: 10em;
    margin: 1px;
    font-size: 1em;
}

input {
    width: 4em;
    margin: 2px;
}

.large-input {
    width: 6em;
    margin: 2px;
}

.line-item button { 
    margin: 2px;
    width: 4em;
    height: 2em;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.simple-col {
    display: flex;
    flex-direction: column;
}

.simple-col button {
    width: 10em;
    margin-bottom: 10px;
    height: 2em;
    padding: 0px;
}